import {
  Button,
  Modal,
  Table
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

const UserCreditHistoryDialog = ({ onClose, creditHistory }) => {
  const parseDescription = (text) => {
    const data = {};
    const splits = text.split('&');
    data['description'] = splits[0];
    const session_id = splits.find((m) => m.includes('session_id='));
    data['session_id'] = session_id ? session_id.replace('session_id=', '') : '';
    return data;
  };
  
  return (
    <Modal show={true} onHide={onClose} backdrop='static' size='xl' className='user-credit-history-dialog'>
      <Modal.Header>Credit History</Modal.Header>
      <Modal.Body>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Date & Time</th>
              <th>Credits</th>
              <th>Media ID</th>
              <th>Customer</th>
              <th>Type</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {
              creditHistory && creditHistory.length > 0 && creditHistory.map((item) => {
                const parseData = parseDescription(item.description);                
                return (
                  <tr>
                    <td>{item.created_at}</td>
                    <td>{item.credit}</td>
                    <td><Link to={`/media/${item.media_id}`}>{item.media_id}</Link></td>
                    <td>{item.customer_id}</td>
                    <td>{item.type}</td>
                    <td>{parseData.description}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export { UserCreditHistoryDialog };
